// HomePage.js
import React from 'react';
import supplierIcon from '@iconify-icons/mdi/account-supervisor-circle';
import utilizerIcon from '@iconify-icons/mdi/group';
import comparisonIcon from '@iconify-icons/mdi/scale-balance';
import HomePageTab from '../homepage-components/HomePageTab';
import '../style/pages-style/HomePage.css';
import Header from '../app_components/Header';
import { translations } from '../app_components/Translation';

function HomeOrganizational({ selectedLanguage }) {
  const selectedText = translations[selectedLanguage];

  const features = [
    {
      icon: supplierIcon,
      title: selectedText.supply_chain,
      description: selectedText.description,
      link: '/organizationalsupplychain'
    },
    {
      icon: utilizerIcon,
      title: selectedText.organization,
      description: selectedText.description,
      link: '/organizationalorganization'
    },
    {
      icon: comparisonIcon,
      title: selectedText.comparisonTitle,
      description: selectedText.description,
      link: '/organizationalcomparison'
    },
  ];

  return (
    <div className="home-page">
      <Header selectedLanguage={selectedLanguage}/>
      {features.map((feature, index) => (
        <HomePageTab
          key={index}
          icon={feature.icon}
          title={feature.title}
          description={feature.description}
          link={feature.link}
        />
      ))}
    </div>
  );
}

export default HomeOrganizational;
