import React, {memo, useEffect, useState} from "react";
import "../style/components-style/ProcessEnvFactorButton.css";
import config from "../config";
import {translations} from "../app_components/Translation";
import {Icon} from "@iconify/react";

function ScopeTableButton({
                              selectedRow,
                              selectedScopes = [],
                              tableGridApi,
                              product,
                              organization,
                              refreshData,
                              selectedLanguage
                          }) {

    const [disableSave, setDisableSave] = useState(true);
    const selectedText = translations[selectedLanguage].processEnvFactorButton;

    const onCellValueChanged = (event) => {
        setDisableSave(false);
    };

    useEffect(() => {
        if (tableGridApi) {
            tableGridApi.addEventListener('cellValueChanged', onCellValueChanged);
        }

        return () => {
            if (tableGridApi) {
                tableGridApi.removeEventListener('cellValueChanged', onCellValueChanged);
            }
        };
    }, [tableGridApi]);

    const createNewEmissionData = () => {
        if (product) {
            return {
                id: undefined,
                category: '',
                description: '',
                organization_id: null,
                product_id: product['id']
            };
        } else {
            return {
                id: undefined,
                category: '',
                description: '',
                organization_id: organization['id'],
                product_id: null
            };
        }
    };

    const addRow = () => {
        if (!tableGridApi) return;
        tableGridApi.applyTransaction({add: [createNewEmissionData()]});
        setDisableSave(false);
    };

    const deleteSelectedRow = () => {
        if (!tableGridApi || selectedScopes.length === 0) return;

        const selectedIds = selectedScopes.map(row => row.id);
        const tableName = 'emissions';

        fetch(`${config.apiUrl}/delete_row`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({table_name: tableName, selected_ids: selectedIds})
        })
            .then(response => response.json())
            .then(data => {
                console.log('Deleted:', data);
                // Remove the deleted rows from the table
                tableGridApi.applyTransaction({remove: selectedScopes});
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const saveTable = () => {
        const requestData = [];
        tableGridApi.forEachNode(node => requestData.push(node.data));
        console.log('requestData:', requestData);

        const endpoint = 'save_emissions';
        fetch(`${config.apiUrl}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                refreshData(); // Refresh the data after saving
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        setDisableSave(true);
    };

    return (
        <div className="button-container">
            <div className="button-container-left">
                <button onClick={addRow} disabled={(!selectedRow)} title="Add a new row in the table">
                    <Icon icon="ic:round-plus" style={{fontSize: '16px'}}/>
                </button>
                <button onClick={deleteSelectedRow} disabled={(selectedScopes.length === 0)}
                        title="Delete selected rows" className="delete-button">
                    <Icon icon="ic:round-minus" style={{fontSize: '16px'}}/>
                </button>
            </div>
            <div className="button-container-right">
                <button onClick={saveTable} disabled={disableSave} title="Save current data in the table to database"><Icon
                    icon="mdi:floppy-disk" style={{fontSize: '16px'}}/></button>
            </div>
        </div>
    )
}

export default memo(ScopeTableButton);