import React from 'react';
import {AgGridReact} from '@ag-grid-community/react';
import {ClientSideRowModelModule} from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../style/components-style/Grid.css';
import {ResizableBox} from 'react-resizable';
import 'react-resizable/css/styles.css';
import {Icon} from '@iconify/react';

const Grid = ({columnDefs, rowData, onGridReady, onSelectionChanged, getRowStyle, customMinHeight}) => {
    const minHeight = customMinHeight || 215;
    const rowHeight = 40;
    const maxHeight = 600;
    const computedHeight = Math.min(Math.max(minHeight, rowData.length * rowHeight), maxHeight);

    return (
        <ResizableBox
            className="grid-resizable-box"
            width={Infinity}
            height={computedHeight}  // Use the passed height prop
            minConstraints={[100, 100]}
            maxConstraints={[Infinity, 800]}
            handle={
                <div className="custom-resize-handle">
                    <Icon icon="vaadin:handle-corner" style={{color: 'grey', fontSize: '20px'}}/>
                </div>
            }
            resizeHandles={['se']}
        >
            <div className="ag-theme-alpine grid-container">
                <AgGridReact
                    modules={[ClientSideRowModelModule, CsvExportModule]}
                    columnDefs={columnDefs}
                    rowData={rowData}
                    domLayout="normal"
                    defaultColDef={{
                        flex: 1,
                        minWidth: 100,
                        resizable: true,
                        sortable: true,
                        filter: true,
                    }}
                    onGridReady={onGridReady}
                    rowSelection="multiple"
                    onSelectionChanged={onSelectionChanged}
                    getRowStyle={getRowStyle}
                />
            </div>
        </ResizableBox>
    );
};

export default Grid;
