import React, {memo, useEffect, useState} from "react";
import "../style/components-style/ProcessEnvFactorButton.css";
import config from "../config";
import {translations} from "../app_components/Translation";
import {Icon} from "@iconify/react";

function DetailsTableButton({selectedScope, selectedDetails = [], tableGridApi, refreshData, selectedLanguage}) {

    const [disableSave, setDisableSave] = useState(true);
    const selectedText = translations[selectedLanguage].processEnvFactorButton;

    const onCellValueChanged = (event) => {
        setDisableSave(false);
        tableGridApi.forEachNode((node) => {
            const {type, category, description, unit} = node.data;

            if (!type || !category || !description || !unit) {
                setDisableSave(true)
            }
        });
    };

    useEffect(() => {
        if (tableGridApi) {
            tableGridApi.addEventListener('cellValueChanged', onCellValueChanged);
        }

        return () => {
            if (tableGridApi) {
                tableGridApi.removeEventListener('cellValueChanged', onCellValueChanged);
            }
        };
    }, [tableGridApi]);

    const createNewDetailsData = () => {
        return {
            id: undefined,
            calculation_method: '',
            type: '',
            category: '',
            description: '',
            quantity: 0,
            unit: '',
            factor: 1,
            totalco2eq: 0.0,
            emission_id: selectedScope.id,
        }
    };

    const addRow = () => {
        if (!tableGridApi) return;
        tableGridApi.applyTransaction({add: [createNewDetailsData()]});
        setDisableSave(false);
    };

    const deleteSelectedRow = () => {
        if (!tableGridApi || selectedDetails.length === 0) return;

        const selectedIds = selectedDetails.map(row => row.id);
        const tableName = 'emission_details';

        fetch(`${config.apiUrl}/delete_row`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({table_name: tableName, selected_ids: selectedIds})
        })
            .then(response => response.json())
            .then(data => {
                console.log('Deleted:', data);
                // Remove the deleted rows from the table
                tableGridApi.applyTransaction({remove: selectedDetails});
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    const saveTable = () => {
        const requestData = [];
        tableGridApi.forEachNode(node => requestData.push(node.data));
        console.log('requestData:', requestData);

        const endpoint = 'save_emission_details';
        fetch(`${config.apiUrl}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestData)
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                refreshData(); // Refresh the data after saving
            })
            .catch((error) => {
                console.error('Error:', error);
            });
        setDisableSave(true);
    };

    return (
        <div className="button-container">
            <div className="button-container-left">
                <button onClick={addRow} disabled={(!selectedScope)} title="Add a new row in the table">
                    <Icon icon="ic:round-plus" style={{fontSize: '16px'}}/>
                </button>
                <button onClick={deleteSelectedRow} disabled={(selectedDetails.length === 0)}
                        title="Delete selected rows" className="delete-button">
                    <Icon icon="ic:round-minus" style={{fontSize: '16px'}}/>
                </button>
            </div>
            <div className="button-container-right">
                <button onClick={saveTable} disabled={disableSave} title="Save current data in the table to database">
                    <Icon
                        icon="mdi:floppy-disk" style={{fontSize: '16px'}}/></button>
            </div>
        </div>
    )
}

export default memo(DetailsTableButton);