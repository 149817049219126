import React, {memo, useEffect, useState} from "react";
import "../style/components-style/TableButton.css";
import config from "../config";
import {useAuth} from "../authentication/AuthProvider";
import Tooltip from "../app_components/Tooltip";
import {translations} from '../app_components/Translation';
import {Icon} from "@iconify/react";

function TableButton({
                         isSupplier,
                         isOrganization,
                         selectedRows,
                         mainTableGridApi,
                         allRows,
                         fetchData,
                         selectedLanguage,
                         gridApi
                     }) {
    const {user} = useAuth();
    const userId = user ? user.username : null;

    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [dropdownValue, setDropdownValue] = useState('All');
    const [disableSave, setDisableSave] = useState(true);

    const selectedText = translations[selectedLanguage].buttons;

    const onCellValueChanged = (event) => {
        setDisableSave(false);
    };

    useEffect(() => {
        if (gridApi) {
            gridApi.addEventListener('cellValueChanged', onCellValueChanged);
        }

        return () => {
            if (gridApi) {
                gridApi.removeEventListener('cellValueChanged', onCellValueChanged);
            }
        };
    }, [gridApi]);

    const handleOpenConfirmModal = () => {
        setIsConfirmModalOpen(true);
    };

    const handleCloseConfirmModal = () => {
        setIsConfirmModalOpen(false);
    };

    const createNewRowData = () => {
        const now = new Date();
        const commonData = {
            Year: 2023,
            gwp_total: 0.0,
            cost: 0.0,
            date_modified: `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')} ${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(2, '0')}:${String(now.getSeconds()).padStart(2, '0')}`,
            user_id: userId
        };

        if (isSupplier) {
            return {
                ...commonData,
                quantity: 0,
                leed_category: '',
                product: '',
                supplier: '',
                sub_category: '',
                cost_per_gwp: 'N/A',
                functional_unit: ''
            };
        } else if (isOrganization) {
            return {
                ...commonData,
                organization: '',
                location: ''
            }
        } else {
            return {
                ...commonData,
                quantity: 0,
                description: '',
                design_installation: '',
                location: ''
            };
        }
    };

    const addRow = () => {
        if (!mainTableGridApi) return;

        const newRow = createNewRowData();
        const tableName = isSupplier ? 'suppliers_products' : isOrganization ? 'organizations' : 'design_installations';

        fetch(`${config.apiUrl}/add_data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                table_name: tableName,
                data: newRow
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => {
                if (data.status === 'success') {
                    mainTableGridApi.applyTransaction({add: [newRow]});
                    fetchData();
                }
            })
            .catch(error => console.error('Error adding data:', error));
    };

    const deleteSelectedRow = () => {
        if (!mainTableGridApi) return;
        const selectedData = mainTableGridApi.getSelectedRows();
        const tableName = isSupplier ? 'suppliers_products' : isOrganization ? 'organizations' : 'design_installations';

        selectedData.forEach(row => {
            fetch(`${config.apiUrl}/delete_data`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({table_name: tableName, id: row.id})
            })
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                    return response.json();
                })
                .then(data => {
                    if (data.status === 'success') {
                        mainTableGridApi.applyTransaction({remove: [row]});
                        fetchData();
                    }
                })
                .catch(error => console.error('Error deleting data:', error));
        });
        handleCloseConfirmModal();
    };

    const duplicateRow = () => {
        if (!mainTableGridApi || selectedRows.length !== 1) return;

        const selectedRow = selectedRows[0];

        fetch(`${config.apiUrl}/duplicate_table_row`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                row_id: selectedRow.id,
                is_supplier: isSupplier,
                is_organization: isOrganization
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => {
                if (data.success) {
                    fetchData();  // Refresh the data to include the new duplicated row
                }
            })
            .catch(error => console.error('Error duplicating row:', error));
    };

    const saveTable = () => {
        const currentDate = new Date();
        const tableName = isSupplier ? 'suppliers_products' : isOrganization ? 'organizations' : 'design_installations';

        allRows.forEach(row => {
            row.date_modified = currentDate.toISOString().slice(0, 19).replace('T', ' ');

            fetch(`${config.apiUrl}/update_data`, {
                method: 'PUT',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    table_name: tableName,
                    data: row,
                    condition: `id = '${row.id}'`
                })
            })
                .then(response => {
                    if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                    return response.json();
                })
                .then(data => {
                    if (data.status === 'success') {
                        fetchData();
                    }
                })
                .catch(error => console.error('Error updating data:', error));
        });

        setDisableSave(true);
    };

    const handleExport = () => {
        const selectedRowIds = selectedRows.map(row => row.id); // Collect all selected row IDs

        fetch(`${config.apiUrl}/api/export-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                dropdownValue,
                rowIds: selectedRowIds,  // Send all selected row IDs
                isSupplier,
                isOrganization
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.blob(); // Get the response as a blob
            })
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `LCA-export-${new Date().toISOString().replace(/[:.]/g, '-')}.zip`);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url); // Clean up
            })
            .catch(error => console.error('Error exporting data:', error));
    };

    // Updated handleImport function for multiple file support
    const handleImport = (event) => {
        const files = event.target.files;
        if (files.length > 0) {
            const promises = Array.from(files).map(file => {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('userId', userId);
                formData.append('isSupplier', isSupplier ? 'true' : 'false'); // Ensure boolean is converted to a string
                formData.append('isOrganization', isOrganization ? 'true' : 'false');

                return fetch(`${config.apiUrl}/import_data`, {
                    method: 'POST',
                    body: formData
                })
                    .then(response => {
                        if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                        return response.json();
                    })
                    .then(data => {
                        if (data.status === 'success') {
                            console.log('File imported successfully:', file.name);
                        } else {
                            console.error('Error in file import:', file.name);
                        }
                    })
                    .catch(error => {
                        console.error('Error importing data for file:', file.name, error);
                    });
            });

            // Wait for all file imports to finish
            Promise.all(promises).then(() => {
                fetchData(); // Refresh data after all files are processed
            });

            setDisableSave(false);
        }
    };


    function ConfirmModal({isOpen, onClose, onConfirm}) {
        if (!isOpen) return null;

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content" onClick={e => e.stopPropagation()}>
                    <button className="modal-close-button" onClick={onClose}>×</button>
                    <div className="modal-header">{selectedText.confirmDeletion}</div>
                    <div className="modal-body">
                        <p>{selectedText.confirmMessage}</p>
                    </div>
                    <div className="modal-footer">
                        <button className="modal-button modal-confirm-button"
                                onClick={onConfirm}>{selectedText.confirm}</button>
                        <button className="modal-button" onClick={onClose}>{selectedText.cancel}</button>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="button-container">
            <div className="button-container-left">
                <button onClick={addRow} title="Add a new row in the table"><Icon icon="ic:round-plus"
                                                                                  style={{fontSize: '16px'}}/></button>
                <button onClick={duplicateRow} disabled={selectedRows.length !== 1}
                        title="Create a copy of selected row"><Icon icon="ion:duplicate" style={{fontSize: '16px'}}/>
                </button>
                <button onClick={handleOpenConfirmModal} disabled={selectedRows.length === 0}
                        title="Delete selected rows" className="delete-button"><Icon icon="ic:round-minus"
                                                                                     style={{fontSize: '16px'}}/>
                </button>
            </div>
            <div className="button-container-right">
                <input type="file" accept=".csv" multiple onChange={handleImport} style={{display: 'none'}}
                       id="import-csv"/>
                <Tooltip message="Make sure to save new values before importing">
                    <button onClick={() => document.getElementById('import-csv').click()} style={{fontSize: '16px'}}
                            title="Import template"><Icon icon="carbon:document-import"/>
                    </button>
                </Tooltip>
                <button onClick={handleExport} disabled={selectedRows.length === 0} style={{fontSize: '16px'}}
                        title="Export template"><Icon
                    icon="carbon:document-export"/></button>
                <button onClick={saveTable} disabled={disableSave || selectedRows.length === 0}
                        title="Save template"><Icon icon="mdi:floppy-disk"
                                                                                 style={{fontSize: '16px'}}/></button>
            </div>
            <ConfirmModal isOpen={isConfirmModalOpen} onClose={handleCloseConfirmModal} onConfirm={deleteSelectedRow}/>
        </div>
    );
}

export default memo(TableButton);
