import React, { useState, useRef, useEffect } from 'react';
import {
    Chart as ChartJS, ArcElement, CategoryScale,
    LinearScale,
    BarElement,
    Title, Tooltip, Legend
} from 'chart.js';
import { Doughnut, Bar } from 'react-chartjs-2';
import { Icon } from '@iconify/react';
import { toPng } from 'html-to-image'; // Import the correct function from html-to-image
import '../style/components-style/Footprint.css';
import { translations } from '../app_components/Translation';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Function to generate distinct RGB colors based on index and total number of items
function getDistinctRGB(index, total) {
    const hue = (index / total) * 360; // Space out colors evenly around the hue circle
    const saturation = 70 + Math.random() * 30; // Random saturation between 70% and 100%
    const lightness = 50 + Math.random() * 10; // Random lightness between 50% and 60%

    // Convert HSL to RGB
    const hslToRgb = (h, s, l) => {
        s /= 100;
        l /= 100;
        const k = n => (n + h / 30) % 12;
        const a = s * Math.min(l, 1 - l);
        const f = n => l - a * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1);
        return [Math.round(f(0) * 255), Math.round(f(8) * 255), Math.round(f(4) * 255)];
    };
    const [r, g, b] = hslToRgb(hue, saturation, lightness);
    return { r, g, b };
}

function Footprint({ processData, selectedLanguage, setFootprintChart, setFootprintDoughnut }) {
    const [isFootprintOverviewOpen, setIsFootprintOverviewOpen] = useState(true);
    const [isEnvironmentalFootprintOpen, setIsEnvironmentalFootprintOpen] = useState(true);
    const [selectedLCAStage, setSelectedLCAStage] = useState('All');
    const doughnutChartContainerRef = useRef(null); // Ref for Doughnut chart container
    const footprintBarChartRef = useRef(null); // Ref for Footprint Bar chart

    const toggleFootprintOverview = () => setIsFootprintOverviewOpen(!isFootprintOverviewOpen);
    const toggleEnvironmentalFootprint = () => setIsEnvironmentalFootprintOpen(!isEnvironmentalFootprintOpen);

    const handleLCAStageChange = (event) => {
        setSelectedLCAStage(event.target.value);
    };

    const selectedText = translations[selectedLanguage].footprint;

    const filteredProcessData = selectedLCAStage === 'All'
        ? processData
        : selectedLCAStage === 'A123'
            ? processData.filter(item => ['A1', 'A2', 'A3'].some(prefix => item.lca_stage.startsWith(prefix)))
            : selectedLCAStage === 'A45'
                ? processData.filter(item => ['A4', 'A5'].some(prefix => item.lca_stage.startsWith(prefix)))
                : processData.filter(item => item.lca_stage.startsWith(selectedLCAStage));

    const barLabels = filteredProcessData.map(item => item.name);
    const barDataPoints = filteredProcessData.map(item => item.gwp);

    const backgroundColors = [];
    const borderColors = [];

    barLabels.forEach((label, index) => {
        const { r, g, b } = getDistinctRGB(index, barLabels.length);
        const opacity = 0.3; // Background color opacity
        backgroundColors.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
        borderColors.push(`rgba(${r}, ${g}, ${b}, 1)`); // Border color is fully opaque
    });

    const barData = {
        labels: barLabels,
        datasets: [{
            label: 'GWP',
            data: barDataPoints,
            backgroundColor: backgroundColors,
            borderColor: borderColors,
            borderWidth: 1.5,
            pointRadius: 3,
            pointHoverRadius: 3,
            pointBackgroundColor: backgroundColors,
            tension: 0.05
        }]
    };

    const barOptions = {
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: true,
                    drawBorder: true,
                    borderColor: 'black', // Set the border color for the gridlines
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: true,
                    drawBorder: true,
                    borderColor: 'black', // Set the border color for the gridlines
                },
                title: {
                    display: true,
                    text: 'GWP [kg CO₂e]',
                    font: {
                        size: 13,
                        weight: 'bold',
                    },
                    padding: {
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 14
                    }
                }
            }
        },
        layout: {
            padding: 20 // Add some padding around the chart
        },
        plugins: {
            legend: {
                display: false
            }
        },
        maintainAspectRatio: true,
        responsive: true
    };

    const totalGWPs = {
        product: 0,
        construction: 0,
        use: 0,
        endOfLife: 0,
        benefits: 0
    };

    processData.forEach(item => {
        const stagePrefix = item.lca_stage.split(':')[0];

        if (['A1', 'A2', 'A3'].some(prefix => stagePrefix.startsWith(prefix))) {
            totalGWPs.product += item.gwp;
        } else if (['A4', 'A5'].some(prefix => stagePrefix.startsWith(prefix))) {
            totalGWPs.construction += item.gwp;
        } else if (stagePrefix.startsWith('B')) {
            totalGWPs.use += item.gwp;
        } else if (stagePrefix.startsWith('C')) {
            totalGWPs.endOfLife += item.gwp;
        } else if (stagePrefix.startsWith('D')) {
            totalGWPs.benefits += item.gwp;
        }
    });

    const totalGWP = Object.values(totalGWPs).reduce((sum, value) => sum + value, 0);

    const doughnutBackgroundColors = [];
    const doughnutBorderColors = [];

    // Doughnut chart colors
    const doughnutData = {
        labels: [selectedText.product, selectedText.construction, selectedText.use, selectedText.endOfLife, selectedText.benefits],
        datasets: [
            {
                data: [totalGWPs.product, totalGWPs.construction, totalGWPs.use, totalGWPs.endOfLife, totalGWPs.benefits],
                backgroundColor: doughnutBackgroundColors,
                borderColor: doughnutBorderColors,
                borderWidth: 1,
            },
        ],
    };

    doughnutData.labels.forEach((label, index) => {
        const { r, g, b } = getDistinctRGB(index, doughnutData.labels.length);
        const opacity = 0.3; // Adjust the opacity for background color
        doughnutBackgroundColors.push(`rgba(${r}, ${g}, ${b}, ${opacity})`);
        doughnutBorderColors.push(`rgba(${r}, ${g}, ${b}, 1)`); // Full opacity for the border
    });

    const doughnutOptions = {
        plugins: {
            legend: {
                display: false
            }
        },
        maintainAspectRatio: true,
        responsive: true
    };

    const downloadChartWithLegend = () => {
        if (doughnutChartContainerRef.current) {
            toPng(doughnutChartContainerRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'footprint_overview.png';
                    link.click();
                })
                .catch((error) => {
                    console.error('Error generating image:', error);
                });
        }
    };

    const downloadFootprintBarChart = () => {
        if (footprintBarChartRef.current) {
            toPng(footprintBarChartRef.current)
                .then((dataUrl) => {
                    const link = document.createElement('a');
                    link.href = dataUrl;
                    link.download = 'environmental_footprint.png';
                    link.click();
                })
                .catch((error) => {
                    console.error('Error generating image:', error);
                });
        }
    };


    // Function to capture chart images and update state
    const captureChartImage = async () => {
        if (doughnutChartContainerRef.current) {
            try {
                const doughnutDataUrl = await toPng(doughnutChartContainerRef.current, { quality: 1 });
                setFootprintDoughnut(doughnutDataUrl);  // Update state with doughnut chart image
            } catch (error) {
                console.error('Error capturing doughnut chart image:', error);
            }
        }
        if (footprintBarChartRef.current) {
            try {
                const barDataUrl = await toPng(footprintBarChartRef.current, { quality: 1 });
                setFootprintChart(barDataUrl);  // Update state with bar chart image
            } catch (error) {
                console.error('Error capturing bar chart image:', error);
            }
        }
    };

    // Use useEffect to trigger chart capture when data or selectedLCAStage changes
    useEffect(() => {
        const timer = setTimeout(() => {
            captureChartImage();
        }, 1000); // 1000 milliseconds = 1 second

        // Cleanup the timer if the component unmounts or dependencies change
        return () => clearTimeout(timer);
    }, [processData, selectedLCAStage]);

    const totalBiogenicGWP = processData.reduce((sum, item) => {
        return sum + (item.biogenic_gwp || 0);
    }, 0);    

    return (
        <div className="footprint-chart-container">
            <div className="footprint-container">
                <div className="container-header-footprint-overview">
                <h2
                        onClick={toggleFootprintOverview}
                        onMouseEnter={() => document.querySelector('.container-header-footprint-overview h2').style.color = 'grey'}
                        onMouseLeave={() => document.querySelector('.container-header-footprint-overview h2').style.color = 'black'}
                        style={{ cursor: 'pointer' }}
                    >
                        {selectedText.footprintOverview}
                    </h2>
                </div>
                {isFootprintOverviewOpen && (
                    <div ref={doughnutChartContainerRef} className="doughnut-chart">
                        <div className="download-icon" onClick={downloadChartWithLegend}>
                            <Icon icon="fe:download" style={{ color: 'grey', cursor: 'pointer' }} />
                        </div>
                        <Doughnut data={doughnutData} options={doughnutOptions} className="footprint-doughnut" />

                        <div className="legend-total-container">
                            <div className="gwp-total-container">
                                <div className="gwp-total-footprint">
                                    <span className="gwp-total-label-footprint">GWP Total:</span>
                                    <span className="gwp-total-value-footprint">{totalGWP.toFixed(2)}</span>
                                </div>
                                <div className="gwp-total-footprint">
                                    <span className="gwp-total-label-footprint">Biogenic GWP Total:</span>
                                    <span className="gwp-total-value-footprint">{totalBiogenicGWP.toFixed(2)}</span>
                                </div>
                            </div>
                            <div className="vertical-divider"> </div>
                            <div className="legend">
                                {doughnutData.labels.map((label, index) => {
                                    const value = doughnutData.datasets[0].data[index].toFixed(2);
                                    const percentage = isNaN((value / totalGWP) * 100) ? '0' : ((value / totalGWP) * 100).toFixed(2);
                                    return (
                                        <div key={label} className="legend-item">
                                            <span
                                                className="legend-color"
                                                style={{ backgroundColor: doughnutData.datasets[0].backgroundColor[index] }}
                                            ></span>
                                            <span className="legend-text">
                                                {label}: {value} [{percentage}%]
                                            </span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="units-info">All units are calculated in kg CO₂e</div>
                    </div>

                )}
            </div>
            <div className="bar-chart">
                <div className="container-header-envfootprint">
                <h2
                        onClick={toggleEnvironmentalFootprint}
                        onMouseEnter={() => document.querySelector('.container-header-envfootprint h2').style.color = 'grey'}
                        onMouseLeave={() => document.querySelector('.container-header-envfootprint h2').style.color = 'black'}
                        style={{ cursor: 'pointer' }}
                    >
                        {selectedText.environmentalFootprint}
                    </h2>
                </div>
                {isEnvironmentalFootprintOpen && (
                    <div ref={footprintBarChartRef} className="footprint-barchart">
                        <div className="download-icon" onClick={downloadFootprintBarChart}>
                            <Icon icon="fe:download" style={{ color: 'grey', cursor: 'pointer' }} />
                        </div>
                        <div className="lca-stage-container">
                            <select value={selectedLCAStage} onChange={handleLCAStageChange} className="lca-stage-select">
                                <option value="All">{selectedText.dropdown.all}</option>
                                <option value="A123">{selectedText.dropdown.product}</option>
                                <option value="A45">{selectedText.dropdown.construction}</option>
                                <option value="B">{selectedText.dropdown.use}</option>
                                <option value="C">{selectedText.dropdown.endOfLife}</option>
                                <option value="D">{selectedText.dropdown.benefits}</option>
                            </select>
                        </div>
                        <Bar data={barData} options={barOptions} className='environmental-footprint-chart' Height={225} />
                    </div>
                )}
            </div>
        </div>
    );
}

export default Footprint;
