import React, {memo, useCallback, useEffect, useRef, useState} from 'react';
import Grid from './Grid';
import config from '../config';
import '../style/components-style/Table.css';
import {Icon} from '@iconify/react';
import TableButton from './TableButton';
import AdditionalInfo from './AdditionalInfo';
import TrackView from './TrackView';
import {useAuth} from "../authentication/AuthProvider";
import {translations} from '../app_components/Translation';
import Collaborate from './Collaborate';

function Table({
                   isSupplier,
                   isOrganization,
                   setSelectedRowData,
                   setMainTableGridApi,
                   setTrackViewState,
                   selectedLanguage,
                   setAllTableRows,
                   childTableRows,
                   pageType
               }) {
    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState([]);
    const [leedCategories, setLeedCategories] = useState([]);
    const [subCategories, setSubCategories] = useState({});
    const [isOpen, setIsOpen] = useState(true);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showDetails, setShowDetails] = useState(false);
    const [activeTab, setActiveTab] = useState('additionalInfo');
    const [additionalInfo, setAdditionalInfo] = useState(null);
    const [editableAdditionalInfo, setEditableAdditionalInfo] = useState(null);
    const [collaborateInfo, setCollaborateInfo] = useState(null);
    const [trackView, setTrackView] = useState(false);
    const [searchText, setSearchText] = useState('');
    const gridApiRef = useRef(null);
    const {user} = useAuth();
    const userId = user ? user.username : null;
    const [isHovered, setIsHovered] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const [showColumnsModal, setShowColumnsModal] = useState(false);
    const [columnDefs, setColumnDefs] = useState([]);

    // Function to format float values
    function formatFloatValue(value, decimalPlaces = 2) {
        return parseFloat(value).toFixed(decimalPlaces);
    }

    // Sorting function to sort rows alphabetically and then numerically based on the data type
    function sortRows(data) {
        return data.sort((a, b) => {
            // Determine the sorting logic based on the available fields (for suppliers, organizations, or utilizers)
            if (isSupplier) {
                // Sorting for suppliers by 'product' (alphabetical) and 'Year' (numerical)
                const nameA = a.product ? a.product.toLowerCase() : '';
                const nameB = b.product ? b.product.toLowerCase() : '';
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return a.Year - b.Year;
            } else if (isOrganization) {
                // Sorting for organizations by 'organization' (alphabetical) and 'Year' (numerical)
                const nameA = a.organization ? a.organization.toLowerCase() : '';
                const nameB = b.organization ? b.organization.toLowerCase() : '';
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return a.Year - b.Year;
            } else {
                // Sorting for utilizers by 'design_installation' (alphabetical) and 'Year' (numerical)
                const nameA = a.design_installation ? a.design_installation.toLowerCase() : '';
                const nameB = b.design_installation ? b.design_installation.toLowerCase() : '';
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;
                return a.Year - b.Year;
            }
        });
    }

    // Function to handle track view change
    const handleTrackViewChange = () => {
        const newTrackViewState = !trackView;
        setTrackView(newTrackViewState);
        setTrackViewState(newTrackViewState);
    };

    // Initialize allRows based on gridApi
    const allRows = gridApi && gridApi.getRenderedNodes ? (gridApi.getRenderedNodes() || []).map(node => node.data) : [];

    // Get the translations for the selected language
    const selectedText = translations[selectedLanguage].table;

    // Fetch data from the backend
    const fetchData = () => {
        if (!userId) return;

        const tableName = isSupplier ? 'suppliers_products' : isOrganization ? 'organizations' : 'design_installations';

        let condition;

        if (isSupplier) {
            condition = `((user_id = '${userId}' AND (collaborator_id IS NULL OR collaborator_id = '')) OR (collaborator_id = '${userId}'))`;
        } else {
            condition = `user_id = '${userId}'`;
        }

        fetch(`${config.apiUrl}/fetch_data`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                table_name: tableName,
                attribute: '*',
                condition: condition
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => {
                const sortedData = sortRows(data || []); // Sort the fetched data
                setAllTableRows(sortedData);
                setRowData(sortedData);
            })
            .catch(error => console.error('Error fetching data:', error));
    };

    // Fetch LEED categories from the backend
    const fetchLeedCategories = () => {
        fetch(`${config.apiUrl}/fetch_data`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({table_name: 'leed_categories', attribute: '*', condition: '1'})
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => {
                const categories = data.map(item => item.category);
                const subCatMap = {};
                data.forEach(item => {
                    subCatMap[item.category] = JSON.parse(item.sub_category);
                });
                setLeedCategories(categories);
                setSubCategories(subCatMap);
            })
            .catch(error => console.error('Error fetching LEED categories:', error));
    };

    useEffect(() => {
        fetchData();
        fetchLeedCategories();
    }, [isSupplier, userId]);

    const updateGWPTotal = () => {
        if (gridApi) {
            const selectedNode = gridApi.getSelectedNodes()[0];
            if (selectedNode) {
                selectedNode.data.gwp_total = childTableRows.reduce((sum, item) => sum + (item.gwp || 0), 0);
                gridApi.applyTransaction({update: [selectedNode.data]});
            }
        }
    }

    useEffect(() => {
        if (isOrganization) {
            updateGWPTotal()
        }
    }, [childTableRows]);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        setMainTableGridApi(params.api);
        gridApiRef.current = params.api;
    }, [setMainTableGridApi]);

    const getSubCategoriesForCategory = (category) => {
        return subCategories[category] || [];
    };

    useEffect(() => {
        if (!gridApi || !gridApi.getRenderedNodes) return;

        const onSelectionChanged = () => {
            const selectedNodes = gridApi.getSelectedNodes() || [];  // Safeguard against undefined
            const selectedData = selectedNodes.map(node => node.data); // Safe to map now
            setSelectedRowData(selectedData);
            setSelectedRows(selectedData);

            if (selectedData.length > 0) {
                setAdditionalInfo(selectedData[0]);
                setEditableAdditionalInfo(selectedData[0]);
                setCollaborateInfo(selectedData[0]);
            } else {
                setAdditionalInfo(null);
                setEditableAdditionalInfo(null);
                setCollaborateInfo(null);
            }
        };

        gridApi.addEventListener('selectionChanged', onSelectionChanged);
        return () => gridApi.removeEventListener('selectionChanged', onSelectionChanged);
    }, [gridApi, setSelectedRowData]);


    const toggleContainer = () => setIsOpen(!isOpen);

    const handleShowDetailsChange = () => {
        setShowDetails(!showDetails);
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setEditableAdditionalInfo(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = () => {
        const currentDate = new Date();
        editableAdditionalInfo.date_modified = currentDate.toISOString().slice(0, 19).replace('T', ' ');

        fetch(`${config.apiUrl}/update_data`, {
            method: 'PUT',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                table_name: isSupplier ? 'suppliers_products' : isOrganization ? 'organizations' : 'design_installations',
                data: editableAdditionalInfo,
                condition: `id = '${editableAdditionalInfo.id}'`
            })
        })
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok: ' + response.statusText);
                return response.json();
            })
            .then(data => {
                if (data.status === 'success') {
                    alert('Additional information updated successfully');
                    setEditableAdditionalInfo(prevState => ({
                        ...prevState,
                        date_modified: currentDate.toISOString().slice(0, 19).replace('T', ' ')
                    }));

                    // Re-fetch data and sort it after saving
                    fetchData();
                }
            })
            .catch(error => console.error('Error updating data:', error));
    };

    const toggleSearchBar = () => {
        setShowSearch(!showSearch);
        setSearchText(''); // Clear the search text when closing the search bar
        if (gridApiRef.current) {
            gridApiRef.current.setQuickFilter(''); // Clear the filter when closing the search bar
        }
    };

    const handleSearch = (event) => {
        setSearchText(event.target.value);
        if (gridApiRef.current) {
            gridApiRef.current.setQuickFilter(event.target.value);
        }
    };

    const toggleColumnVisibility = (field) => {
        if (gridApi) {
            const currentState = gridApi.getColumnState();
            const targetColumn = currentState.find((col) => col.colId === field);
            if (targetColumn) {
                gridApi.setColumnVisible(field, targetColumn.hide);
                setColumnDefs((prevColumnDefs) =>
                    prevColumnDefs.map((col) => {
                        if (col.field === field) {
                            // Toggle the hide property in the columnDefs state
                            return {...col, hide: !targetColumn.hide};
                        }
                        return col;
                    })
                );
            }
        }
    };

    const exportCsv = () => {
        if (gridApi) {
            const now = new Date();

            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const day = String(now.getDate()).padStart(2, '0');

            const formattedDateTime = `${year}-${month}-${day}`;

            if (isOrganization) {
                if (isSupplier) {
                    gridApi.exportDataAsCsv({
                        fileName: `SupplyChainTable - ${formattedDateTime}.csv`
                    });
                } else {
                    gridApi.exportDataAsCsv({
                        fileName: `OrganizationTable - ${formattedDateTime}.csv`
                    });
                }
            } else {
                if (isSupplier) {
                    gridApi.exportDataAsCsv({
                        fileName: `SupplierTable - ${formattedDateTime}.csv`
                    });
                } else {
                    gridApi.exportDataAsCsv({
                        fileName: `UtilizerTable - ${formattedDateTime}.csv`
                    });
                }
            }
        }
    };

    // Define column definitions for suppliers, utilizers, and organizations
    const getColumnDefs = () => {
        // Function to determine if a cell is editable
        const isEditable = (params) => {
            const {data} = params;

            // Check if the current user is the collaborator and set fields to non-editable except quantity
            if (data.collaborator_id === userId) {
                return params.colDef.field === 'quantity';
            }

            // Default logic: fields are editable if validated !== 1
            return data.validated !== 1;
        };

        const supplierColumnDefs = [
            {field: 'id', hide: true},
            {
                headerName: selectedText.product,
                field: 'product',
                cellEditor: 'agTextCellEditor',
                checkboxSelection: true,
                headerCheckboxSelection: true,
                minWidth: 250,
                editable: isEditable,
                hide: false
            },
            {
                headerName: selectedText.supplier,
                field: 'supplier',
                editable: isEditable,
                cellEditor: 'agTextCellEditor',
                hide: false
            },
            {
                headerName: selectedText.leedCategory,
                field: 'leed_category',
                cellEditor: 'agSelectCellEditor',
                editable: isEditable,
                cellEditorParams: {values: leedCategories},
                cellRenderer: params => params.value,
                hide: false
            },
            {
                headerName: selectedText.subCategory,
                field: 'sub_category',
                editable: isEditable,
                cellEditor: 'agSelectCellEditor',
                minWidth: 250,
                cellEditorParams: params => ({values: getSubCategoriesForCategory(params.data.leed_category)}),
                hide: false
            },
            {
                headerName: selectedText.year,
                field: 'Year',
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {values: [2023, 2024, 2025]},
                editable: isEditable,
                width: 100,
                cellStyle: {textAlign: 'center'},
                headerClass: "ag-center-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.quantity,
                field: 'quantity',
                editable: isEditable,
                cellEditor: 'agNumberCellEditor',
                width: 100,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.gwpTotal,
                field: 'gwp_total',
                editable: false,
                cellEditor: 'agTextCellEditor',
                valueFormatter: params => formatFloatValue(params.value, 2),
                width: 200,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.cost,
                field: 'cost',
                editable: isEditable,
                cellEditor: 'agTextCellEditor',
                valueFormatter: params => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.costPerGwp,
                field: 'cost_per_gwp',
                cellEditor: 'agTextCellEditor',
                editable: false,
                valueGetter: params => {
                    const cost = params.data.cost;
                    const gwp = params.data.gwp_total;
                    return gwp > 0 ? (cost / gwp).toFixed(2) : 'N/A';
                },
                width: 125,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {field: 'functional_unit', hide: true},
            {field: 'date_modified', hide: true}
        ];

        const utilizerColumnDefs = [
            {field: 'id', hide: true},
            {
                headerName: selectedText.designInstallation,
                field: 'design_installation',
                editable: true,
                cellEditor: 'agTextCellEditor',
                minWidth: 400,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                hide: false
            },
            {
                headerName: selectedText.location,
                field: 'location',
                editable: true,
                cellEditor: 'agTextCellEditor',
                minWidth: 200,
                hide: false
            },
            {
                headerName: 'Description',
                field: 'description',
                cellEditor: 'agTextCellEditor',
                hide: true,
                minWidth: 200
            },
            {
                headerName: selectedText.year,
                field: 'Year',
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {values: [2023, 2024, 2025]},
                width: 100,
                cellStyle: {textAlign: 'center'},
                headerClass: "ag-center-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.quantity,
                field: 'quantity',
                editable: true,
                cellEditor: 'agNumberCellEditor',
                width: 100,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.gwpTotal,
                field: 'gwp_total',
                editable: false,
                cellEditor: 'agTextCellEditor',
                valueFormatter: params => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.cost,
                field: 'cost',
                editable: true,
                cellEditor: 'agTextCellEditor',
                valueFormatter: params => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.costPerGwp,
                field: 'cost_per_gwp',
                cellEditor: 'agTextCellEditor',
                editable: false,
                valueGetter: params => {
                    const cost = params.data.cost;
                    const gwp = params.data.gwp_total;
                    return gwp > 0 ? (cost / gwp).toFixed(2) : 'N/A';
                },
                width: 125,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {field: 'date_modified', hide: true}
        ];

        const organizationColumnDefs = [
            {field: 'id', hide: true},
            {
                headerName: selectedText.organization,
                field: 'organization',
                editable: true,
                cellEditor: 'agTextCellEditor',
                minWidth: 400,
                checkboxSelection: true,
                headerCheckboxSelection: true,
                hide: false
            },
            {
                headerName: selectedText.location,
                field: 'location',
                editable: true,
                cellEditor: 'agTextCellEditor',
                minWidth: 200,
                hide: false
            },
            {
                headerName: selectedText.year,
                field: 'Year',
                editable: true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {values: [2023, 2024, 2025]},
                width: 100,
                cellStyle: {textAlign: 'center'},
                headerClass: "ag-center-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.gwpTotal,
                field: 'gwp_total',
                editable: false,
                cellEditor: 'agTextCellEditor',
                valueFormatter: params => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.cost,
                field: 'cost',
                editable: true,
                cellEditor: 'agTextCellEditor',
                valueFormatter: params => formatFloatValue(params.value, 2),
                width: 125,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {
                headerName: selectedText.costPerGwp,
                field: 'cost_per_gwp',
                cellEditor: 'agTextCellEditor',
                editable: false,
                valueGetter: params => {
                    const cost = params.data.cost;
                    const gwp = params.data.gwp_total;
                    return gwp > 0 ? (cost / gwp).toFixed(2) : 'N/A';
                },
                width: 125,
                cellStyle: {textAlign: 'right'},
                headerClass: "ag-right-aligned-header",
                hide: false
            },
            {field: 'date_modified', hide: true}
        ]

        return isSupplier ? supplierColumnDefs : isOrganization ? organizationColumnDefs : utilizerColumnDefs;
    };

    useEffect(() => {
        setColumnDefs(getColumnDefs());
    }, []);

    const getRowStyle = (params) => {
        if (params.data && params.data.collaborator_id) {
            console.log("Applying blue background for row with collaborator_id:", params.data.collaborator_id);
            return {background: '#c9cac98f'};
        } else {
            return null;
        }
    };

    const productsGridProps = {
        columnDefs: columnDefs,
        rowData: rowData,
        rowSelection: 'multiple',
        onGridReady: onGridReady,
        domLayout: 'autoHeight',
        getRowStyle: getRowStyle,
    };

    const columnSelectionModal = () => {
        if (!gridApi) return null;

        return (
            <div className="columns-modal">
                <button className="close-modal-button" onClick={() => setShowColumnsModal(false)}>
                    <Icon icon="mdi:close" style={{fontSize: '20px', color: 'black'}}/>
                </button>
                {columnDefs.map((column) => {
                    if (column.field !== "id") {
                        const colState = gridApi.getColumnState().find((col) => col.colId === column.field);

                        return (
                            <div key={column.field}>
                                <input
                                    type="checkbox"
                                    checked={!colState?.hide}
                                    onChange={() => toggleColumnVisibility(column.field)}
                                />
                                <label>{column.headerName || column.field}</label>
                            </div>
                        );
                    }
                })}
            </div>
        );
    };

    return (
        <div className="main-table">
            <div className="main-table-contain">
                <div className="container-header-main">
                    <h2
                        onClick={toggleContainer}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                        style={{
                            cursor: 'pointer',
                            color: isHovered ? 'grey' : 'black', // Change 'blue' to your desired hover color
                        }}
                    >
                        {isSupplier
                            ? selectedText.tableTitle
                            : isOrganization
                                ? selectedText.organization
                                : selectedText.designInstallationsTitle}
                    </h2>
                </div>
                {isOpen && (
                    <div className="content-container">
                        <div className="left-panel">
                            <div className="grid-container">
                                <div className="table-top-button-container">
                                    {showSearch ? (
                                        <div className="search-bar-container">
                                            <input
                                                type="text"
                                                placeholder="Search..."
                                                value={searchText}
                                                onChange={handleSearch}
                                                style={{padding: '5px', width: '200px', marginRight: '10px'}}
                                            />
                                            <button onClick={() => handleSearch({target: {value: ''}})}
                                                    style={{padding: '5px'}}>
                                                Clear
                                            </button>
                                            <button
                                                onClick={toggleSearchBar}
                                                style={{
                                                    background: 'none',
                                                    border: 'none',
                                                    cursor: 'pointer',
                                                    marginLeft: '5px'
                                                }}
                                            >
                                                <Icon icon="mdi:close" style={{fontSize: '20px', color: 'black'}}/>
                                            </button>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {/* Right Buttons Container */}
                                    <div className="right-buttons-container">
                                        {!showSearch && (
                                            <button className="search-button" onClick={toggleSearchBar}
                                                    title="Search for keywords in table">
                                                <Icon icon="mdi:magnify"/>
                                            </button>
                                        )}
                                        <button className="show-column-button"
                                                onClick={() => setShowColumnsModal(!showColumnsModal)}
                                                title="Show/Hide Columns">
                                            <Icon icon="material-symbols:view-column-sharp"/>
                                        </button>
                                        {showColumnsModal && columnSelectionModal()}

                                        {/* Export CSV Button */}
                                        <button className="csv-button" onClick={exportCsv}
                                                title="Export current table as CSV">
                                            <Icon icon="mdi:file-export"/>
                                        </button>
                                    </div>
                                </div>
                                <div id="my-grid-container">
                                    <Grid {...productsGridProps} />
                                </div>
                                <div className="checkbox-container-bottom">
                                    <input type="checkbox" id="toggle-details" name="toggle-details"
                                           checked={showDetails} onChange={handleShowDetailsChange}/>
                                    <label htmlFor="toggle-details">{selectedText.toggleDetails}</label>
                                    <input type="checkbox" id="toggle-view" name="toggle-view"
                                           checked={trackView}
                                           onChange={handleTrackViewChange}/>
                                    <label htmlFor="toggle-view">{selectedText.trackView}</label>
                                </div>
                                <TableButton
                                    isSupplier={isSupplier}
                                    isOrganization={isOrganization}
                                    selectedRows={selectedRows}
                                    mainTableGridApi={gridApi}
                                    allRows={allRows}
                                    fetchData={fetchData}
                                    selectedLanguage={selectedLanguage}
                                    gridApi={gridApi}
                                />
                            </div>
                        </div>
                        {showDetails && selectedRows.length > 0 && isSupplier && (
                            <div className="details-container">
                                <div className="tab-header">
                    <span className={activeTab === 'additionalInfo' ? 'active' : ''}
                          onClick={() => handleTabClick('additionalInfo')}>Additional Information
            </span>
                                    <span className={activeTab === 'collaborate' ? 'active' : ''}
                                          onClick={() => handleTabClick('collaborate')}>Collaborate</span>
                                </div>
                                <div className="tab-content">
                                    {activeTab === 'additionalInfo' && additionalInfo && (
                                        <AdditionalInfo
                                            isSupplier={isSupplier}
                                            additionalInfo={additionalInfo}
                                            editableAdditionalInfo={editableAdditionalInfo}
                                            handleInputChange={handleInputChange}
                                            handleSave={handleSave}
                                            pageType={pageType}
                                        />
                                    )}
                                    {activeTab === 'collaborate' && collaborateInfo && (
                                        <Collaborate
                                            collaborateInfo={collaborateInfo}
                                            mainTableGridApi={gridApi}
                                            fetchData={fetchData}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                        {
                            showDetails && selectedRows.length > 0 && (!isSupplier) && (
                                <div className="details-container">
                                    <div className="tab-header">
                                    <span className={activeTab === 'additionalInfo' ? 'active' : ''}
                                          onClick={() => handleTabClick('additionalInfo')}>Additional Information</span>
                                    </div>
                                    <div className="tab-content">
                                        {activeTab === 'additionalInfo' && additionalInfo && (
                                            <AdditionalInfo
                                                isSupplier={isSupplier}
                                                additionalInfo={additionalInfo}
                                                editableAdditionalInfo={editableAdditionalInfo}
                                                handleInputChange={handleInputChange}
                                                handleSave={handleSave}
                                                pageType={pageType}
                                            />
                                        )}
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
                }
            </div>
            {
                trackView && (
                    <TrackView selectedRows={selectedRows}/>
                )
            }
        </div>
    )
        ;
}

export default memo(Table);
