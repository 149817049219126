// Translation.js
export const translations = {
    EN: {
        contactInfo: 'Emailus@vrtta.green | +1 (438) 882-5385',
        copyrightInfo: 'Copyright © ',
        arivu: 'Arivu.',
        vrtta: 'VRTTA Green Solutions. All rights reserved.',
        product: 'Product',
        organizational: 'Organizational',
        supplier: 'Supplier',
        utilizer: 'Utilizer',
        generateReport: 'Generate Report',
        supply_chain: 'Supply Chain',
        organization: 'Organization',
        comparisonTitle: 'Comparison',
        reuseTitle: 'Reuse',
        description: '',
        table: {
            product: 'Product',
            supplier: 'Supplier',
            leedCategory: 'LEED Category',
            subCategory: 'Sub Category',
            year: 'Year',
            quantity: 'Quantity',
            gwpTotal: 'GWP Total (kg CO₂e)',
            cost: 'Cost($)',
            costPerGwp: 'Cost Per GWP',
            designInstallation: 'Design Installation',
            location: 'Location',
            tableTitle: 'Products',
            designInstallationsTitle: 'Design Installations',
            displayAdditionalInfo: 'Display additional information',
            collaborate: 'Collaborate',
            trackView: 'Global View',
            toggleDetails: 'Details',
            organization: 'Organization',
        },
        buttons: {
            addRow: 'Add Row',
            duplicate: 'Duplicate',
            delete: 'Delete',
            importCsv: 'Import Template',
            exportCsv: 'Export Template',
            save: 'Save',
            confirmDeletion: 'Confirm Deletion',
            confirmMessage: 'Are you sure you want to delete the selected rows?',
            confirm: 'Delete',
            cancel: 'Cancel'
        },
        comparison: {
            supplier: 'Supplier',
            utilizer: 'Utilizer',
            supply_chain: 'Supply Chain',
            organization: 'Organization',
            inventoryAsOf: 'Inventory as of',
            selectComparisonType: 'Select Comparison Type',
            comparisonBasedOn: 'Comparison Based On',
            selectValuesToCompare: 'Select Values to Compare',
            apply: 'Apply',
            environmentalFootprint: 'Environmental Footprint',
            footprintSummary: 'Footprint Summary',
            percentageDifference: 'Percentage Difference',
            gwp: 'GWP [kg CO₂e]',
            phases: 'Phases',
            productPhase: 'Product',
            constructionPhase: 'Construction',
            usePhase: 'Use',
            endOfLifePhase: 'End-of-life',
            benefitsPhase: 'Benefits',
            scopes: 'Scopes',
            scope1: 'Scope1',
            scope2: 'Scope2',
            scope3: 'Scope3',
            scope4: 'Scope4',
            total: 'Total',
            products: 'Products',
            organizations: 'Organizations',
            percentageDifferenceChart: 'Percentage Difference (%)',
            lessThan: '% less than',
            moreThan: '% more than'
        },
        phaseCard: {
            average: 'Average'
        },
        reuse: {
            product: "Product",
            donorCredit: "Donor Credit (kg CO₂e)",
            receiverCredit: "Receiver Credit (kg CO₂e)",
            designInstallation: "Design Installation",
            selectAtLeastOne: "Please select at least one product or design installation.",
            donorCredits: "Donor Credits",
            receiverCredits: "Receiver Credits",
            gridNotReady: "Grid is not ready",
            csvFileName: "reuse_data.csv",
            reuseType: "Reuse Type",
            supplier: "Supplier",
            utilizer: "Utilizer",
            products: "Avoided Emissions",
            designInstallations: "Design Installations",
            exportAsCsv: "Export as CSV",
            loadingData: "Loading data...",
            generatedGraph: "Calculated Credits",
            credits: "Credits (kg CO₂e)"
        },
        login: {
            username: "Username",
            password: "Password",
            invalidCredentials: "Invalid username or password",
            enterUsername: "Enter your username",
            enterPassword: "Enter your password",
            login: "Login"
        },
        termsAndConditions: {
            termsHeader: "Terms and Conditions",
            termsContent: [
                "These are the terms and conditions...",
                "More details about the terms and conditions..."
            ],
            decline: "Decline",
            accept: "Accept"
        },
        lifeCycleAssessment: {
            title: "Life Cycle Assessment",
            generalInformation: "General Information",
            referencePCR: "Reference PCR",
            declaredUnit: "Declared Unit",
            scope: "Scope",
            lcaSoftware: "LCA Software",
            lciDatabase: "LCI Database(s)",
            lciaMethodology: "LCIA Methodology",
            cutOff: "Cut-Off",
            allocationProcedure: "Allocation Procedure",
            primarySources: "Primary Sources of LCI Data",
            interpretation: "Interpretation",
            references: "References",
            methodology: "Methodology"
        },
        systemBoundary: {
            supplierTitle: "System Boundary (Cradle To Gate)",
            utilizerTitle: "System Boundary (Cradle To Grave)",
            productStage: "Product stage",
            rawMaterialSupply: "Raw Material Supply",
            transportToFactory: "Transport to Factory",
            manufacturing: "Manufacturing",
            constructionStage: "Construction stage",
            transportToSite: "Transport to Site",
            constructionInstallation: "Construction-Installation",
            useStage: "Use stage",
            use: "Use",
            maintenance: "Maintenance",
            repair: "Repair",
            replacement: "Replacement",
            refurbishment: "Refurbishment",
            endOfLifeStage: "End-of-life stage",
            deconstructionDemolition: "Deconstruction / Demolition",
            transportToWasteDisposal: "Transport to Waste / Disposal",
            wasteProcessing: "Waste Processing",
            disposalOfWaste: "Disposal of Waste",
            beyondBuildingLifeCycle: "Beyond the building life cycle",
            reuseRecoveryRecyclingPotential: "Reuse-Recovery-Recycling Potential"
        },
        header: {
            supplier: 'Supplier',
            utilizer: 'Utilizer',
            comparison: 'Comparison',
            supply_chain: 'Supply Chain',
            organization: 'Organization',
            reuse: 'Reuse',
        },
        sideMenu: {
            support: 'Support',
            settings: 'Settings',
            profile: 'Profile'
        },
        processTable: {
            processes: 'Processes',
            lcaStage: 'LCA Stage',
            process: 'Processes',
            gwp: 'GWP Total (kg CO₂e)',
            all: 'All',
            product: 'Product',
            construction: 'Construction',
            use: 'Use',
            endOfLife: 'End-of-life',
            benefits: 'Benefits',
            headers: {
                process: 'Process',
                gwp: 'GWP Total (kg CO₂e)',
                lcaStage: 'LCA Stage'
            }
        },
        scopeTable: {
            scope: 'Emissions',
            all: 'All',
            scope1: 'Scope1',
            scope2: 'Scope2',
            scope3: 'Scope3',
            scope4: 'Scope4',
            headers: {
                category: 'Category',
                description: 'Description',
                gwp: 'GWP Total (kg CO₂e)',
            }
        },
        processEnvFactorButton: {
            addRow: 'Add Row',
            deleteSelectedRow: 'Delete',
            saveTable: 'Save'
        },
        footprint: {
            footprintOverview: 'Footprint Overview',
            product: 'Product',
            construction: 'Construction',
            use: 'Use',
            endOfLife: 'End-of-life',
            benefits: 'Benefits',
            emissionGraph: 'Emission Graph',
            detailsGraph: 'Details Graph',
            environmentalFootprint: 'Environmental Footprint',
            dropdown: {
                all: 'All',
                product: 'Product (A1-A3)',
                construction: 'Construction (A4-A5)',
                use: 'Use (B)',
                endOfLife: 'End-of-life (C)',
                benefits: 'Benefits (D)',
            },
            scopeDropdown: {
                scope1: 'Scope1',
                scope2: 'Scope2',
                scope3: 'Scope3',
                scope4: 'Scope4',
            }
        },
        envFactorTable: {
            envFactors: 'Environmental Factors',
            type: 'Type',
            category: 'Category',
            description: 'Description',
            unit: 'Unit',
            factor: 'Factor',
            quantity: 'Quantity',
            gwpTotal: 'GWP Total (kg CO₂e)',
            gwpUnit: 'GWP / Unit',
        },
        scopeCategoryDetailsTable: {
            scopeDetails: 'Details',
            calculationMethod: 'Method',
            type: 'Type',
            category: 'Category',
            description: 'Description',
            unit: 'Unit',
            factor: 'Factor',
            quantity: 'Quantity',
            gwpUnit: "GWP/Unit",
            TotalCO2Eq: 'Total CO₂e (kg)',
        },
        GHGInfoTable: {
            ghgInfo: 'GHG Information',
        },
        profile: {
            loginInfo: 'Login Info',
            email: 'Email',
            password: 'Password',
            userProfile: 'User Profile',
            company: 'Company',
            address: 'Address',
            phoneNumber: 'Phone Number',
            uploadLogo: 'Upload Company Logo'
        },
        support: {
            userGuide: 'User Guide'
        }
    },
    FR: {
        contactInfo: 'Envoyez-nous un e-mail à vrtta.green | +1 (438) 882-5385',
        copyrightInfo: 'Droits d\'auteur © ',
        arivu: 'Arivu.',
        vrtta: 'Solutions vertes VRTTA. Tous droits réservés.',
        product: 'Produit',
        organizational: 'Organisationnel',
        supplier: 'Fournisseur',
        utilizer: 'Utilisateur',
        generateReport: 'Générer un rapport',
        supply_chain: 'Chaîne d\'approvisionnement',
        organization: 'Organisation',
        comparisonTitle: 'Comparaison',
        reuseTitle: 'Réutiliser',
        description: '',
        table: {
            product: 'Produit',
            supplier: 'Fournisseur',
            leedCategory: 'Catégorie LEED',
            subCategory: 'Sous-catégorie',
            year: 'Année',
            quantity: 'Quantité',
            gwpTotal: 'GWP Total (kg CO₂e)',
            cost: 'Coût($)',
            costPerGwp: 'Coût par GWP',
            designInstallation: 'Installation de Conception',
            location: 'Emplacement',
            tableTitle: 'Produits',
            designInstallationsTitle: 'Installations de Conception',
            displayAdditionalInfo: 'Afficher des informations supplémentaires',
            collaborate: 'Collaborer',
            trackView: 'Vue Globale',
            toggleDetails: 'Détails',
            organization: 'Organisation'
        },
        buttons: {
            addRow: 'Ajouter une ligne',
            duplicate: 'Dupliquer',
            delete: 'Supprimer',
            importCsv: 'Importer Modèle',
            exportCsv: 'Exporter Modèle',
            save: 'Enregistrer',
            confirmDeletion: 'Confirmer la suppression',
            confirmMessage: 'Êtes-vous sûr de vouloir supprimer les lignes sélectionnées ?',
            confirm: 'Supprimer',
            cancel: 'Annuler'
        },
        comparison: {
            supplier: 'Fournisseur',
            utilizer: 'Utilisateur',
            supply_chain: 'Chaîne d\'approvisionnement',
            organization: 'Organisation',
            inventoryAsOf: 'Inventaire au',
            selectComparisonType: 'Sélectionner le type de comparaison',
            comparisonBasedOn: 'Comparaison basée sur',
            selectValuesToCompare: 'Sélectionnez les valeurs à comparer',
            apply: 'Appliquer',
            environmentalFootprint: 'Empreinte environnementale',
            footprintSummary: 'Résumé de l\'empreinte',
            percentageDifference: 'Différence en pourcentage',
            gwp: 'GWP [kg CO₂e]',
            phases: 'Phases',
            productPhase: 'Produit',
            constructionPhase: 'Construction',
            usePhase: 'Utilisation',
            endOfLifePhase: 'Fin de vie',
            benefitsPhase: 'Avantages',
            scopes: 'Cadres',
            scope1: 'Cadre1',
            scope2: 'Cadre2',
            scope3: 'Cadre3',
            scope4: 'Cadre4',
            total: 'Total',
            products: 'Produits',
            organizations: 'Organisations',
            percentageDifferenceChart: 'Différence en pourcentage (%)',
            lessThan: '% moins que',
            moreThan: '% plus que',
            exportDocx: "Générer le rapport"
        },
        phaseCard: {
            average: 'Moyenne'
        },
        reuse: {
            product: "Produit",
            quantity: "Quantité de crédit",
            donorCredit: "Crédit Donateur (kg CO₂e)",
            receiverCredit: "Crédit Récepteur (kg CO₂e)",
            designInstallation: "Installation de Conception",
            selectAtLeastOne: "Veuillez sélectionner au moins un produit ou une installation de conception.",
            donorCredits: "Crédits Donateurs",
            receiverCredits: "Crédits Récepteurs",
            gridNotReady: "La grille n'est pas prête",
            csvFileName: "donnees_reutilisation.csv",
            reuseType: "Type de Réutilisation",
            supplier: "Fournisseur",
            utilizer: "Utilisateur",
            products: "Émissions Évitées",
            designInstallations: "Installations de Conception",
            exportAsCsv: "Exporter au CSV",
            saveChanges: "Enregistrer",
            loadingData: "Chargement des données...",
            generatedGraph: "Crédits Calculés",
            credits: "Crédits (kg CO₂e)",
            exportDocx: "Générer le rapport",
        },
        login: {
            username: "Nom d'utilisateur",
            password: "Mot de passe",
            invalidCredentials: "Nom d'utilisateur ou mot de passe invalide",
            enterUsername: "Entrez votre nom d'utilisateur",
            enterPassword: "Entrez votre mot de passe",
            login: "Connexion"
        },
        termsAndConditions: {
            termsHeader: "Termes et Conditions",
            termsContent: [
                "Voici les termes et conditions...",
                "Plus de détails sur les termes et conditions..."
            ],
            decline: "Refuser",
            accept: "Accepter"
        },
        lifeCycleAssessment: {
            title: "Évaluation du Cycle de Vie",
            generalInformation: "Informations Générales",
            referencePCR: "PCR de Référence",
            declaredUnit: "Unité Déclarée",
            scope: "Portée",
            lcaSoftware: "Logiciel LCA",
            lciDatabase: "Base de Données LCI",
            lciaMethodology: "Méthodologie LCIA",
            cutOff: "Couper",
            allocationProcedure: "Procédure d'Allocation",
            primarySources: "Principales Sources De Données LCI",
            interpretation: "Interprétation",
            references: "Références",
            methodology: "Méthodologie"
        },
        systemBoundary: {
            supplierTitle: "Limite du système (du Berceau à la Porte)",
            utilizerTitle: "Limite du Système (Du Berceau à la Tombe)",
            productStage: "Étape du produit",
            rawMaterialSupply: "Approvisionnement en Matières Premières",
            transportToFactory: "Transport à l'Usine",
            manufacturing: "Fabrication",
            constructionStage: "Étape de construction",
            transportToSite: "Transport vers le Site",
            constructionInstallation: "Construction-Installation",
            useStage: "Étape d'utilisation",
            use: "Utilisation",
            maintenance: "Entretien",
            repair: "Réparation",
            replacement: "Remplacement",
            refurbishment: "Rénovation",
            endOfLifeStage: "Étape de fin de vie",
            deconstructionDemolition: "Déconstruction / Démolition",
            transportToWasteDisposal: "Transport vers les Déchets / Élimination",
            wasteProcessing: "Traitement des Déchets",
            disposalOfWaste: "Élimination des Déchets",
            beyondBuildingLifeCycle: "Au-delà du cycle de vie de la fabrication",
            reuseRecoveryRecyclingPotential: "Potentiel de Réutilisation-Récupération-Recyclage"
        },
        header: {
            supplier: 'Fournisseur',
            utilizer: 'Utilisateur',
            supply_chain: 'Chaîne d\'approvisionnement',
            organization: 'Organisation',
            comparison: 'Comparaison',
            reuse: 'Réutiliser',
        },
        sideMenu: {
            support: 'Support',
            settings: 'Paramètres',
            profile: 'Profil',
            logout: 'Se déconnecter'
        },
        processTable: {
            processes: 'Processus',
            lcaStage: 'Étape LCA',
            process: 'Processus',
            gwp: 'GWP Total (kg CO₂e)',
            all: 'Tout',
            product: 'Produit',
            construction: 'Construction',
            use: 'Utilisation',
            endOfLife: 'Fin de vie',
            benefits: 'Avantages',
            headers: {
                process: 'Processus',
                gwp: 'GWP Total (kg CO₂e)',
                lcaStage: 'Étape LCA'
            }
        },
        scopeTable: {
            scope: 'Emissions',
            all: 'Tout',
            scope1: 'Cadre1',
            scope2: 'Cadre2',
            scope3: 'Cadre3',
            scope4: 'Cadre4',
            headers: {
                category: 'Catégorie',
                description: 'Description',
                gwp: 'GWP Total (kg CO₂e)',
            }
        },
        processEnvFactorButton: {
            addRow: 'Ajouter',
            deleteSelectedRow: 'Supprimer',
            saveTable: 'Enregistrer'
        },
        footprint: {
            footprintOverview: 'Vue d\'ensemble de l\'empreinte',
            product: 'Produit',
            construction: 'Construction',
            use: 'Utilisation',
            endOfLife: 'Fin de vie',
            benefits: 'Avantages',
            emissionGraph: 'Graphique des émissions',
            detailsGraph: 'Graphique des détails',
            environmentalFootprint: 'Empreinte environnementale',
            dropdown: {
                all: 'Tout',
                product: 'Produit (A1-A3)',
                construction: 'Construction (A4-A5)',
                use: 'Utilisation (B)',
                endOfLife: 'Fin de vie (C)',
                benefits: 'Avantages (D)',
            },
            scopeDropdown: {
                scope1: 'Cadre1',
                scope2: 'Cadre2',
                scope3: 'Cadre3',
                scope4: 'Cadre4',
            }
        },
        envFactorTable: {
            envFactors: 'Facteurs Environnementaux',
            type: 'Type',
            category: 'Catégorie',
            description: 'Description',
            unit: 'Unité',
            factor: 'Facteur',
            quantity: 'Quantité',
            gwpTotal: 'GWP Total (kg CO₂e)',
            gwpUnit: 'Unité GWP',
        },
        scopeCategoryDetailsTable: {
            scopeDetails: 'Détails',
            calculationMethod: 'Méthode',
            type: 'Type',
            category: 'Catégorie',
            description: 'Description',
            unit: 'Unité',
            factor: 'Facteur',
            quantity: 'Quantité',
            TotalCO2Eq: 'Éq. CO₂ total (kg)',
            gwpUnit: "GWP/Unité",
        },
        GHGInfoTable: {
            ghgInfo: 'Information de GHG',
        },
        profile: {
            loginInfo: 'Informations de connexion',
            email: 'Email',
            password: 'Mot de passe',
            userProfile: 'Profil de l\'utilisateur',
            company: 'Entreprise',
            address: 'Adresse',
            phoneNumber: 'Numéro de téléphone',
            uploadLogo: 'Télécharger un logo d\'entreprise'
        },
        support: {
            userGuide: 'Guide de l\'utilisateur'
        }
    }
};
